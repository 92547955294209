// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeErase,
    plotFunction,
    createAxes,
    createSpace,
    drawPoint,
    writeMediumText,
    writeSmallText,
    writeLargeText
} from '../../../../common/edliy.js';
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph = createSpace(-10, 10, -5, 15);
graph.options.layer['image'] =12;
graph.options.layer['circle'] =13;
graph.options.layer['chart'] =14;
graph.options.layer['sector'] =15;
graph.options.layer['line'] =15;
/////////////////////////////////////////////////////////////////////
makeResponsive(graph);
/////////////////////////////////////////////////////////////
createAxes(graph);
placeTitle(graph, 'Functions & Relations');
placeLogo(graph);
var i=1; var j=0; var x ='x'; var y ='y=f(x)';
var shuffle = placeShuffle(graph);
var test =placeTest(graph);
var rght = placeImage(graph, '/assets/check.svg', -7., 8);
var wrng = placeImage(graph, '/assets/cross.svg', -7., 8);
rght.setAttribute({visible:false});
wrng.setAttribute({visible:false});
shuffle.on('down', function(){
  rght.setAttribute({visible:false});
  wrng.setAttribute({visible:false});
  lin.setAttribute({dash:2, strokeWidth:2});
  quad.setAttribute({dash:2, strokeWidth:2});
  cubc.setAttribute({dash:2, strokeWidth:2});
  if(i<3){i+=1;}else{i=1}
});
test.on('down', function(){
  wrng.setAttribute({visible:false});
  rght.setAttribute({visible:false});
  if(i==j){rght.setAttribute({visible:true})}else{wrng.setAttribute({visible:true})}});

var array =[];
var domain=[x, -1, -2, 1, 2];
var linRange = [y, -1, -2, 1, 2];
var quadRange=[y, 1, 4, 1, 4];
var absRange=[y, 2, 4, 2, 4];
array.push(domain);
array.push(linRange);
array.push(quadRange);
array.push(absRange);
/////////////////////////////Quadratic FUNCTION//////////////
var quad =plotFunction(graph, function(x){return x*x}, -5, 5);
quad.setAttribute({strokeWidth:2, highlight:true, dash:2, strokeColor:'red'});
/////////////////////////////LINEAR FUNCTION//////////////
var lin = plotFunction(graph, function(x){return x}, -10, 10);
lin.setAttribute({strokeWidth:2, highlight:true, dash:2, strokeColor:'blue'});
///////////////////////////////////////////////////
/////////////////////////////Cubic FUNCTION//////////////
var cubc = plotFunction(graph, function(x){return 2*Math.abs(x)}, -10, 10);
cubc.setAttribute({strokeWidth:2, highlight:true, dash:2, strokeColor:'green'});
//////////////////////////////////////////////////////////
quad.on('down', function(){j=2; cubc.setAttribute({dash:2, strokeWidth:2}); lin.setAttribute({dash:2, strokeWidth:2});
quad.setAttribute({dash:0, strokeWidth:4})});
lin.on('down', function(){j=1; cubc.setAttribute({dash:2, strokeWidth:2}); quad.setAttribute({dash:2, strokeWidth:2});
lin.setAttribute({dash:0, strokeWidth:4})});
cubc.on('down', function(){j=3; lin.setAttribute({dash:2, strokeWidth:2}); quad.setAttribute({dash:2, strokeWidth:2});
cubc.setAttribute({dash:0, strokeWidth:4})});
/////////////////////////////////////////////
//writeLargeText(graph, -8, 7, 'x');
//writeLargeText(graph, -6, 7, 'y=f(x)');
//////////////////////////////////
for(let k=0; k<5; k++){
writeMediumText(graph, -7.5, 6+0.5-k, ()=>array[0][k]);
writeMediumText(graph, -6+0.5, 6+0.5-k, ()=>array[i][k]);
}
///////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////END OF OPERATIONS///////////////////////////////
//brd1.unsuspendUpdate();
},
}
export default Boxes;
